






import Vue from "vue";
import StaffNotificationPage from "../../views/staffnotification/StaffNotification.vue";
export default Vue.extend({
  name: "StaffNotificationIndex",
  components: {
    StaffNotificationPage,
  },
});
